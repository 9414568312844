<template>
  <div class="signup">
    <div class="row signup-content">
      <div class="col-12 signup-content-card">
        <div class="row justify-content-center">
          <div class="col-auto ">
            <b-overlay :show="loading" rounded="sm" class="card">
              <div class="card-body d-flex flex-column justify-content-between ">
                <div class="text-center">
                  <p class="mb-4">
                    <img src="@/assets/images/tendencys.svg" class="img-80" alt="">
                  </p>
                  <h5 class="text-center f-w-600" v-text="$t('messages.createTendencysAccount')"></h5>
                </div>
                <v-observer ref="formSignup" tag="form" class="" @submit.prevent="fnValidateSignup()">
                  <v-validation rules="required|validEmail" v-slot="{ validated,passed,errors }"
                    :name="$t('login.email')">
                    <b-form-group :label="$t('login.email')" label-for="input-formatter">
                      <b-input-group>
                        <template #prepend>
                          <b-input-group-text class=" bg-light"> <i class="fa text-muted fa-at"></i>
                          </b-input-group-text>
                        </template>
                        <b-form-input v-model="user.email" :disabled="disabled.email" type="email"
                          :state=" (validated) ? passed : null " id="userEmail" @change="fnValidateEmail()"
                          @input="signupErrors.email = false" placeholder="Email">
                        </b-form-input>
                      </b-input-group>
                      <b-form-invalid-feedback :state="(validated) ? passed : null" v-text="errors[0]">
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </v-validation>
                  <b-alert :show="signupErrors.email" variant="danger">
                    <p class="m-0" v-text="$t('messages.emailOrPhoneAlreadyReg')"></p>
                  </b-alert>
                  <div class="">
                    <div class="row">
                      <div class="col-12 col-md-6">
                        <v-validation rules="required|min:2|max:35" v-slot="{ validated,passed,errors }"
                          :name="$t('general.form.name')">
                          <b-form-group :label="$t('general.form.name')" label-for="input-formatter">
                            <b-input-group>
                              <b-form-input v-model="user.name" type="text" :state=" (validated) ? passed : null "
                                placeholder="Robert"></b-form-input>
                            </b-input-group>
                            <b-form-invalid-feedback :state="(validated) ? passed : null" v-text="errors[0]">
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </v-validation>
                      </div>
                      <div class="col-12 col-md-6">
                        <v-validation rules="required|min:2|max:35" v-slot="{ validated,passed,errors }"
                          :name="$t('general.form.lastName')">
                          <b-form-group :label="$t('general.form.lastName')" label-for="input-formatter">
                            <b-input-group>
                              <b-form-input v-model="user.lastName" type="text" :state=" (validated) ? passed : null "
                                id="userLastname" placeholder="Martin">
                              </b-form-input>
                            </b-input-group>
                            <b-form-invalid-feedback :state="(validated) ? passed : null" v-text="errors[0]">
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </v-validation>
                      </div>
                      <div class="col-12 col-md-6">
                        <v-validation rules="required" v-slot="{ validated,passed,errors }"
                          :name=" $t('general.form.country') ">
                          <b-form-group :label="$t('general.form.country')" label-for="input-formatter">
                            <b-input-group>
                              <template #prepend>
                                <b-input-group-text class=" bg-light">
                                  <i class="fa text-muted fa-flag"></i>
                                </b-input-group-text>
                              </template>
                              <b-form-select @change="fnSetPhoneCode" v-model="user.country" :options="countriesList"
                                value-field="code" text-field="name">
                              </b-form-select>
                            </b-input-group>
                            <b-form-invalid-feedback :state="(validated) ? passed : null" v-text="errors[0]">
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </v-validation>
                      </div>

                      <div class="col-12 col-md-6">
                        <v-validation rules="required|min:10|max:15" v-slot="{ validated,passed,errors }"
                          :name="$t('general.form.phone')">
                          <b-form-group :label="$t('general.form.phone')" label-for="input-formatter">
                            <b-input-group>
                              <template #prepend>
                                <b-input-group-text class=" bg-light">
                                  <strong v-text=" '+' + user.phoneCode" v-if="user.phoneCode"></strong>
                                  <i class="fa text-muted fa-phone" v-else></i>
                                </b-input-group-text>
                              </template>
                              <b-form-input v-model="user.phone" no-wheel type="number"
                                @input="signupErrors.email = false" :state=" (validated) ? passed : null "
                                id="userPhone" placeholder=" 80 8080 8080 ">
                              </b-form-input>
                            </b-input-group>
                            <b-form-invalid-feedback :state="(validated) ? passed : null" v-text="errors[0]">
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </v-validation>
                      </div>

                      <div class="col-12">
                        <hr class="">
                      </div>
                      <div class="col-12 col-md-6">
                        <v-validation rules="required|validPass" v-slot="{ validated,passed,errors }"
                          :name="$t('login.password')">
                          <b-form-group :label="$t('login.password')" label-for="input-formatter">
                            <b-input-group class="">
                              <template #prepend>
                                <b-input-group-text class=" bg-light">
                                  <i class="fa text-muted fa-key"></i>
                                </b-input-group-text>
                              </template>
                              <b-form-input v-model="user.password" type="password"
                                :state=" (validated) ? passed : null " id="userPassword" placeholder="*******">
                              </b-form-input>
                            </b-input-group>
                            <b-form-invalid-feedback :state="(validated) ? passed : null" v-text="errors[0]">
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </v-validation>
                      </div>
                      <div class="col-12 col-md-6">
                        <v-validation rules="required|validPass" v-slot="{ validated,passed,errors }"
                          :name="$t('general.form.passwordRepeat')">
                          <b-form-group :label="$t('general.form.passwordRepeat')" label-for="input-formatter">
                            <b-input-group class="">
                              <template #prepend>
                                <b-input-group-text class=" bg-light">
                                  <i class="fa text-muted fa-key"></i>
                                </b-input-group-text>
                              </template>
                              <b-form-input v-model="user.passwordConfirmation" type="password"
                                :state=" (validated) ? passed : null " id="userRepeatPassword" placeholder="*******">
                              </b-form-input>
                            </b-input-group>
                            <b-form-invalid-feedback :state="(validated) ? passed : null" v-text="errors[0]">
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </v-validation>
                      </div>
                    </div>
                  </div>
                  <b-alert
                    :show=" user.password !== user.passwordConfirmation && (user.password !== null) && (user.passwordConfirmation !== null) "
                    variant="danger">
                    <p class="m-0" v-text="$t('recoveryPassword.alertPasswords')"></p>
                  </b-alert>
                  <div class="row">
                    <div class="col-12 col-md-6 mb-2 mb-md-0 ">
                      <b-button variant="link" class="w-100" v-text="$t('messages.haveAnAccount')"
                        :to="{ path: '/accounts/login', query: { ...$route.query }}">
                      </b-button>
                    </div>
                    <div class="col-12 col-md-6">
                      <b-button variant="primary" class="w-100" @click="fnValidateSignup()">
                        <div v-if="loading">
                          <i class="fa fa-spinner fa-spin"></i>
                        </div>
                        <div v-else>
                          <span v-text="$t('general.signup')"></span>
                        </div>
                      </b-button>
                    </div>
                  </div>
                </v-observer>
              </div>
            </b-overlay>
            <div class="row justify-content-between">
              <div class="col-auto text-center col-md-auto">
                <Languages variant='link text-muted p-0' />
              </div>
              <div class="col-auto col-md-auto">
                <div class="row justify-content-center">
                  <div class="col-auto">
                    <p>
                      <router-link class="text-muted f-w-500" to="/accounts/help" v-text="$t('general.help')">
                      </router-link>
                    </p>
                  </div>
                  <div class="col-auto">
                    <p>
                      <router-link class="text-muted f-w-500" to="/accounts/privacy" v-text="$t('general.privacy')">
                      </router-link>
                    </p>
                  </div>
                  <div class="col-auto">
                    <p>
                      <router-link class="text-muted f-w-500" to="/accounts/conditions"
                        v-text="$t('general.conditions')"> </router-link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  import Languages from "@/components/Languages";
  import SignupCode from "./components/signup-code";
  export default {
    components: {
      Languages,
      SignupCode,
    },
    data() {
      return {
        user: {
          siteId: null,
          email: null,
          password: null,
          name: null,
          lastName: null,
          passwordConfirmation: null,
          country: null,
          phone: null,
          phoneCode: null,
          to: null,
        },
        countriesList: [],
        signupErrors: {
          email: false,
          code: false,
        },
        disabled: {
          email: false,
        },
        verifiacitonTime: 0,
        activationCode: null,
        timeInterval: null,
        step: 1,
        loading: false,
      }
    },
    methods: {
      fnSetPhoneCode(phoneCode) {
        this.user.phoneCode = this.countriesList.find(country => country.code == phoneCode).phone_code || null;
      },
      async fnValidateSignup() {
        if (await this.$refs.formSignup.validate()) {
          this.fnApiRegister();
        }
      },
      async fnApiRegister() {
        this.loading = true;
        await axiosAccount.post("register", {
          first_name: this.user.name,
          last_name: this.user.lastName,
          email: this.user.email,
          country: this.user.country,
          phone: this.user.phone,
          password: this.user.password,
          site_id: this.user.siteId,
          code: this.user.phoneCode,
          to: this.user.to || '/',
        }).then(async response => {
          await setTimeout(async () => {
            this.$router.push({
              path: `/accounts/new-user?email=${this.user.email}&site_id=${this.user.siteId}`
            });
          }, 500);
        }).catch(error => {
          if (error.response.status == 403) {
            this.signupErrors.email = true;
          }
        });
        setTimeout(() => {
          this.loading = false;
        }, 300);
      },
      async fnValidateEmail() {
        if (this.user.email) {
          this.signupErrors.email = false;
          await axiosAccount.get(`validate/email/${this.user.email}`).then(async response => {
            setTimeout(() => {
              if (response.data.status == 1) {
                this.signupErrors.email = false;
              } else {
                this.signupErrors.email = true;
              }
            }, 500);
          }).catch(error => {});
        }
      }
    },
    // Query params: site_id
    async mounted() {
      this.countriesList = await this.fnApiGetCountries();
      this.countriesList.unshift({
        code: null,
        name: 'Please select some item',
        disabled: true
      });
      if (this.$route.query.site_id) {
        this.user.siteId = this.$route.query.site_id;
      } else {
        this.$router.push('/accounts');
      }
      if (this.$route.query.email) {
        this.user.email = this.$route.query.email;
        this.disabled.email = true;
      }
      if (this.$route.query.country) {
        this.user.country = this.$route.query.country;
        this.fnSetPhoneCode(this.$route.query.country);
      }
      if (this.$route.query.first_name) {
        this.user.name = this.$route.query.first_name;
      }
      if (this.$route.query.last_name) {
        this.user.lastName = this.$route.query.last_name;
      }
      if (this.$route.query.phone) {
        this.user.phone = this.$route.query.phone;
      }
      if (this.$route.query.to) {
        this.user.to = this.$route.query.to;
      }
    }
  }
</script>

<style lang="scss">
  .signup {
    background-color: black;
    padding-top: 25vh;

    .signup-content {
      min-height: 75vh;
      background-color: white;

      .signup-content-card {
        .card {
          min-height: 500px;
          width: 600px;
        }

        transform: translateY(-10vh);

        @media only screen and (max-width: 767px) {
          .card {
            min-height: 500px;
            width: auto;
          }

          transform: translateY(-15vh);
        }
      }

      .signup-content-message {
        min-height: 25vh;
      }

      @media only screen and (max-width: 767px) {
        padding-left: 1rem;
        padding-right: 1rem;
      }


    }
  }
</style>